<template>
    <div style="min-height:100vh;background:#fff">
        <div class="top_fa">
            <div class="kongbai1"></div>
            <div class="top_fa_title">{{details.title}}</div>
            <div class="top_fa_money ">
                <span v-if="details.money_type==1" class="col_0ABBB9">-{{details.amount}}元</span>
                <span v-else class="col_F62E2E">+{{details.amount}}元</span>
            </div>
        </div>
        <div class="top_biaoqian">
            <div class="top_biaoqian_lt">资金类型</div>
            <div class="top_biaoqian_rt">{{details.detail_type_desc}}</div>
        </div>
        <div class="top_biaoqian">
            <div class="top_biaoqian_lt">操作金额</div>
            <div class="top_biaoqian_rt">{{details.amount}}元</div>
        </div>
        <div class="top_biaoqian">
            <div class="top_biaoqian_lt">操作时间</div>
            <div class="top_biaoqian_rt">{{details.gmt_create}}</div>
        </div>
        <div class="top_biaoqian" v-if="details.note">
            <div class="top_biaoqian_lt">备注</div>
            <div class="top_biaoqian_rt">{{details.note}}</div>
        </div>
        <!-- -->
        <div class="bottom_back" @click="gobanck">
            <div class="bottom_back_on">
                <div class="bottom_back_wenz">返回</div>
                <div class="iconfont icon-fanhui"></div>
            </div>
        </div>
        <!-- <div class="footer">
            <div @click="save" :style="is_click ? 'background:#aaa' : ''">确认</div>
        </div> -->
        <!-- 弹窗 -->
 
        <!-- 弹窗 -->
    </div>
</template>
<script>

import '@/assets/css/index.css'
// import { send_sms_get } from "@/api/login";
// import { getInfo, postInfo, mobile_bind } from "@/api/home";
export default {
    data() {
        return {
            time: 0, // 倒计时
            status:1,
            details: {},
            my_lizhi:false,
            mobile: '', // 手机号码
            code: '', // 验证码
            is_form: 'home', // home，apply: 从home，apply过来的要请求接口，info：过来的不需要请求接口
            is_click: false
        }
    },
    
    mounted() {
        this.$store.commit("getWxSDK");
        window.scrollTo(0, 0)
        var data = {
            detail_id: this.$route.query.id
        }
        this.Request('client/jobseeker/wallet/detail','get',data).then((res) => {
          if(res.status == 0){
              this.details = res.data
          }  
        })
        this.$store.commit("hideTabbar");
        
    },
    methods: {
        gobanck(){
            this.$router.go(-1);
        },
    }
}
</script>
<style scoped>
.top_biaoqian{
    width: calc(100% - 60px);
    margin-left: 30px;
    height: 85px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}
.top_biaoqian_lt{
    width: 165px;
    height: 85px;
    line-height: 85px;
    text-align: left;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
}
.top_biaoqian_rt{
    width: calc(100% - 180px);
    height: 85px;
    line-height: 85px;
    text-align: left;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
}
.kongbai1{
    width: 100%;
    height: 72px;
}
.top_fa_title{
    width: 100%;
    height: 45px;
    line-height: 45px;
    text-align: center;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
}
.top_fa_money{
    width: 100%;
    height: 75px;
    line-height: 75px;
    margin-top: 22px;
    text-align: center;
    font-size: 54px;
    font-family: PingFang SC;
    font-weight: bold;
}
.top_fa{
    width: calc(100% - 60px);
    margin-left: 30px;
    height: 275px;
    border-bottom: 2px solid #F8F8F8;;
}
</style>